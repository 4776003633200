.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 2rem;
    background-color: #222;
  }
  
  .header h1 {
    color: #fff;
    margin: 0;
  }
  
  .header nav {
    display: flex;
    gap: 1rem;
  }
  
  .header nav a {
    color: #fff;
    text-decoration: none;
    transition: color 0.3s ease;
  }
  
  .header nav a:hover {
    color: #ccc;
  }
  
  .footer {
    padding: 1rem 2rem;
    background-color: #222;
    color: #fff;
    text-align: center;
  }
  .mainTitle{
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
  }
  .hero {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 60vh;
    background-size: cover;
    background-position: center;
    color: #000000;
  }
  
  .hero h2 {
    font-size: 3rem;
  }
  

  .main{
    min-height: calc(92.7vh - 87.69px);
  }
  
  .priveImageModel video{
    width: 100%;
  }
.App {
  text-align: center;
  font-family: 'Roboto', sans-serif;
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem;
}

h1 {
  font-size: 2.5rem;
  margin-bottom: 1.5rem;
  color: #222;
}

.uploadContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px dashed #ccc;
  border-radius: 8px;
  padding: 2rem;
  cursor: pointer;
  transition: all 0.3s ease;
}

.uploadContainer:hover {
  border-color: #888;
}

.uploadContainer input {
  display: none;
}

.uploadContainer button {
  font-size: 1.2rem;
  background-color: #4caf50;
  border: none;
  border-radius: 4px;
  color: #fff;
  padding: 0.5rem 1rem;
  cursor: pointer;
  transition: all 0.3s ease;
}

.uploadContainer button:hover {
  background-color: #3d8b40;
}

.mainContent {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 2rem;
  padding: 2rem;
}


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --primary-color: #4caf50;
  --secondary-color: #f44336;
  --background-color: #ffffff;
  --text-color: #333333;
}

header,
footer {
  flex-shrink: 0;
}

main {
  flex-grow: 1;
}

.button {
  background-color: var(--primary-color);
  border: none;
  color: white;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 4px;
}

.button:hover {
  background-color: var(--secondary-color);
  transition: background-color 0.3s ease-in-out;
}

@media (max-width: 768px) {
  /* CSS rules for smaller screens */
}

h1 {
  font-size: 2rem;
  font-weight: bold;
  color: var(--primary-color);
}

h2 {
  font-size: 1.5rem;
  font-weight: bold;
  color: var(--secondary-color);
}

.container {
  padding: 20px;
}

.element {
  margin: 10px;
}
.container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.leftColumn {
  flex: 1;
}

.hidden {
  display: none;
}


.colorInfo {
  margin-bottom: 20px;
}

select {
  margin-bottom: 20px;
  display: block;
}

.canvas-slide {
  transition: transform 0.5s ease-in-out;
}

.canvas-hidden {
  transform: translateX(-100%);
}

.colorExtractor {
  display: flex;
  gap: 2rem;
}

.imageAndSwatches {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.sceneContainer {
  position: relative;
  display: inline-block;
}

.swatchesOverlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none;
}

.brushStroke {
  filter: url(#brush-stroke);
}
